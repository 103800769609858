import { Theme, useMediaQuery } from "@mui/material";

export const getMuiSize = (theme: Theme): string => {
  const width = window.innerWidth;
  if (width <= theme.breakpoints.values.sm) {
    return "xs";
  } else if (width <= theme.breakpoints.values.md) {
    return "sm";
  } else if (width <= theme.breakpoints.values.lg) {
    return "md";
  } else if (width <= theme.breakpoints.values.xl) {
    return "lg";
  } else {
    return "xl";
  }
};
