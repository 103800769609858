import React, { useEffect } from "react";
import "./App.css";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import qs from "query-string";
import { format } from "date-fns";

import Q1 from "./Q1";

const InitValues = {
  user_id: "",

  p0_q1: "0",

  p1_q1: "",
  p1_q2: "",
  p1_q3: "",
  p1_q4: "",
  p1_q5: "",
  p1_q6: "",
  p1_q7: "",
  p1_q8: "",
  p1_q9: "",
  p1_q10_1: "0",
  p1_q10_2: "0",
  p1_q10_3: "0",
  p1_q10_4: "0",
  p1_q10_5: "0",
  p1_q10_6: "0",
  p1_q10_7: "0",
  p1_q10_8: "0",

  p2_q1: "",
  p2_q2: "",
  p2_q3_1: "23",
  p2_q3_2: "0",
  p2_q3_3: "0",
  p2_q3_4: "7",
  p2_q3_5: "0",
  p2_q4_1: "8",
  p2_q4_2: "0",
  p2_q5_1: "23",
  p2_q5_2: "0",
  p2_q5_3: "0",
  p2_q5_4: "7",
  p2_q5_5: "0",
  p2_q6_1: "0",
  p2_q6_2: "0",
  p2_q6_3: "0",
  p2_q6_4: "0",
  p2_q6_5: "0",
  p2_q6_6: "0",
  p2_q6_7: "0",
  p2_q6_8: "0",
  p2_q6_9: "0",
  p2_q6_10: "0",
  p2_q6_11: "0",
  p2_q6_12: "0",
  p2_q6_13: "0",
  p2_q6_14: "0",
  p2_q6_15: "0",
  p2_q7_1: "0",
  p2_q7_2: "0",
  p2_q7_3: "0",
  p2_q7_4: "0",
  p2_q7_5: "0",
  p2_q7_6: "0",
  p2_q7_7: "0",
  p2_q7_8: "0",
  p2_q7_9: "0",
  p2_q7_10: "0",
  p2_q7_11: "0",
  p2_q7_12: "0",
  p2_q8_1: "0",
  p2_q8_2: "0",
  p2_q8_3: "0",
  p2_q8_4: "0",
  p2_q8_5: "0",
  p2_q8_6: "0",
  p2_q8_7: "0",
  p2_q8_8: "0",
  p2_q8_9: "0",
  p2_q8_10: "0",

  p3_q1: "",
  p3_q2: "",
  p3_q3: "",
  p3_q4: "",
  p3_q5_1: "",
  p3_q5_2: "",
  p3_q5_3: "",
  p3_q5_4: "",
  p3_q5_5: "",
  p3_q6_1: "160",
  p3_q6_2: "60",
  p3_q7: "",
  p3_q8: "",
};

export type VALUES = typeof InitValues;
export type SET_VALUES = React.Dispatch<React.SetStateAction<VALUES>>;

function App() {
  const initValues = Object.assign({}, InitValues, {
    started_at: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
  });

  const [finishedAt, setFinishedAt] = React.useState<Date | undefined>(
    undefined
  );
  const [values, setValues] = React.useState<VALUES>(initValues);

  // const search = qs.parse(window.location.search);
  const user_id = window.location.search.slice(1);
  const localStorageName = "wako2024:" + user_id;
  const postValues = Object.assign({}, values) as {
    [key: string]: string | undefined;
  };
  for (const key in postValues) {
    const v = postValues[key];
    if (v === "NaN" || v === "NULL") {
      postValues[key] = "";
    }
  }
  postValues["finished_at"] = finishedAt
    ? format(finishedAt, "yyyy-MM-dd HH:mm:ss")
    : undefined;

  function calculateCheckDigit(number: string, alphabets: string) {
    let sum = parseInt(number, 10);
    for (let i = 0; i < alphabets.length; i++) {
      sum += alphabets.charCodeAt(i);
    }
    return String.fromCharCode(65 + (sum % 26));
  }

  function validateID(user_id: string): boolean {
    if (user_id == "demo") {
      return true;
    }

    // フォーマット検証
    if (!/^\d{6}-[A-Z]{3}-[A-Z]{2}[A-Z]$/.test(user_id)) {
      return false;
    }

    const parts = user_id.split("-");
    const number = parts[0];
    const alphabets = parts[1];
    const checkDigit = parts[2].slice(-1); // 末尾のアルファベット

    const calculatedCheckDigit = calculateCheckDigit(number, alphabets);

    if (calculatedCheckDigit == checkDigit) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    // ページがロードされたときに実行される処理をここに記述
    if (validateID(user_id)) {
      setValues({
        ...values,
        user_id: user_id,
      });
    } else {
      window.location.href = "/401.html";
    }

    // NOTE: 前回データの確認
    const dataStr = localStorage.getItem(localStorageName);
    if (!dataStr) return;
    const data: {
      done?: boolean;
      values?: VALUES;
      expire?: number;
    } = JSON.parse(dataStr);
    if (data && data.values && data.expire && data.done !== undefined) {
      const now = Math.floor(new Date().getTime() / 1000);
      if (now > data.expire) {
        localStorage.removeItem(localStorageName);
        return;
      }
      if (user_id !== data.values["user_id"]) return;

      if (data.done) {
        confirmAlert({
          title: "既に回答済みです",
          message:
            "もう一度回答したい場合には、「再回答する」を押してください。",
          buttons: [
            {
              label: "再回答する",
              onClick: () => {
                localStorage.removeItem(localStorageName);
                window.location.reload();
              },
            },
          ],
          closeOnClickOutside: false,
        });
        return;
      }

      confirmAlert({
        title: "回答途中の入力内容が見つかりました。",
        message:
          "以前の回答を反映するには、「続きから」を押してください。「始めから」を押すと始めからの入力となります。",
        buttons: [
          {
            label: "続きから",
            onClick: () =>
              setValues({
                ...values,
                ...data.values,
              }),
          },
          {
            label: "始めから",
            onClick: () => {
              localStorage.removeItem(localStorageName);
              window.location.reload();
            },
          },
        ],
        closeOnClickOutside: false,
      });
    }

    // return () => {}; // コンポーネントがアンマウントされるときに実行されるクリーンアップ処理をここに記述
  }, []); // 第二引数に空の依存配列を渡すことで、初回のみ実行されるようになります

  // localstorageへの保存
  React.useEffect(() => {
    // 初期状態は無視
    if (
      Object.keys(values)
        .filter((key) => !["user_id", "started_at"].includes(key))
        .every(
          (key) =>
            values[key as keyof VALUES] === initValues[key as keyof VALUES]
        )
    )
      return;

    localStorage.setItem(
      localStorageName,
      JSON.stringify({
        done: false,
        values: values,
        expire: Math.floor(new Date().getTime() / 1000) + 60 * 60 * 24 * 7, // NOTE: 期限(秒) 7日
      })
    );
  }, [values]);

  // サーバーへの一時保存
  React.useEffect(() => {
    // 初期状態は無視
    if (
      Object.keys(values)
        .filter((key) => !["user_id", "started_at"].includes(key))
        .every(
          (key) =>
            values[key as keyof VALUES] === initValues[key as keyof VALUES]
        )
    )
      return;

    const handlePostRequest = async () => {
      try {
        const data = postValues as { [key: string]: string | null };
        for (const key in data) {
          const v = data[key];
          if (v === "" || v === undefined) {
            data[key] = null;
          }
        }

        const response = await fetch("save.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error(error);
      }
    };
    handlePostRequest();
  }, [values["p1_q9"], values["p2_q5_5"], values["p3_q1"]]);

  const isValid = values.user_id !== "";
  return (
    <React.Fragment>
      <div className="App">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                アンケート
              </Typography> */}
            </Toolbar>
          </AppBar>
        </Box>
        <Container
          className="App-body"
          component="main"
          maxWidth="lg"
          sx={{ mb: 4 }}
        >
          <React.Fragment>
            {isValid === true && (
              <div>
                <form
                  onInvalid={(e) => {
                    const target = e.target;
                    // console.log(target);

                    // NOTE: iphoneだとradioやselectでrequiredが効かないようなので手動で移動
                    const inputs = document.querySelectorAll("input,select");
                    for (let i = 0; i < inputs.length; i++) {
                      const input = inputs[i];
                      if (
                        input instanceof HTMLInputElement ||
                        input instanceof HTMLSelectElement
                      ) {
                        if (!input.validity?.valid) {
                          input.scrollIntoView({
                            block: "center",
                            behavior: "smooth",
                          });
                          break;
                        }
                      }
                    }
                  }}
                  onSubmit={(e) => {
                    // const object: { [name: string]: any } = {};
                    // const data = new FormData(document.forms[0]);
                    // data.forEach((value, key) => (object[key] = value));
                    // console.log(object);
                    // e.preventDefault();
                  }}
                  method="POST"
                  action="result.php"
                >
                  {Object.entries(postValues).map(([key, value]) => (
                    <input key={key} type="hidden" name={key} value={value} />
                  ))}

                  <Q1 values={values} setValues={setValues} />
                  <Box
                    sx={{ display: "flex", justifyContent: "center" }}
                    style={{ marginTop: 20 }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, ml: 1 }}
                      size="large"
                      onClick={() => {
                        console.log(values);
                        setFinishedAt(new Date());
                      }}
                    >
                      完了
                    </Button>
                  </Box>
                </form>
              </div>
            )}
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default App;
