import * as React from "react";
import {
  Theme,
  useTheme,
  GlobalStyles,
  Button,
  lighten,
  FormGroup,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Slider from "@mui/material/Slider";

import { Element as ScrollElement } from "react-scroll";

import {
  getHours,
  addDays,
  addMinutes,
  set,
  differenceInMinutes,
  eachMinuteOfInterval,
  getMinutes,
  format,
  isWithinInterval,
} from "date-fns";

import { scrollTo } from "./utils/scroll";
import { getMuiSize } from "./utils/sizing";

import { VALUES, SET_VALUES } from "./App";

interface Props {
  id: string;
}

export const Component: React.FC<{
  values: VALUES;
  setValues: SET_VALUES;
}> = ({ values, setValues }) => {
  const theme = useTheme<Theme>();
  const muiSize = getMuiSize(theme);
  const isMobile = muiSize === "xs";

  const Spacing = 3;

  const is_show_p2_q8_10 = !Array.from({ length: 9 }, (_, i) => i + 1).some(
    (v) => ["1", "2", "3"].includes(values[`p2_q8_${v}` as keyof VALUES])
  );

  React.useEffect(
    () => {
      if (!is_show_p2_q8_10) {
        setValues({
          ...values,
          p2_q8_10: "0",
        });
      }
    },
    Array.from({ length: 9 }, (_, i) => i + 1).map(
      (v) => values[`p2_q8_${v}` as keyof VALUES]
    )
  );

  React.useEffect(() => {
    const calc_tst = (): number | null => {
      let bedin_hours = values["p2_q3_1"];
      const bedin_minutes = values["p2_q3_2"];
      const sl = values["p2_q3_3"];
      const bedout_hours = values["p2_q3_4"];
      const bedout_minutes = values["p2_q3_5"];
      try {
        if (bedin_hours == "24") {
          bedin_hours = "0";
        }

        let bedinDate = new Date(`2022/01/01 ${bedin_hours}:${bedin_minutes}`);
        if (getHours(bedinDate) < 12) {
          bedinDate = addDays(bedinDate, 1);
        }

        const sleepDate = addMinutes(bedinDate, parseInt(sl, 10));

        let bedoutDate = set(sleepDate, {
          hours: parseInt(bedout_hours, 10),
          minutes: parseInt(bedout_minutes, 10),
        });
        if (differenceInMinutes(bedoutDate, sleepDate) < 0) {
          bedoutDate = addDays(bedoutDate, 1);
        }

        const tst = differenceInMinutes(bedoutDate, sleepDate);
        if (tst < 0 || isNaN(tst)) return null;
        return tst;
      } catch (err) {
        console.error(err);
        return null;
      }
    };

    const tst = calc_tst();
    if (tst) {
      setValues({
        ...values,
        p2_q4_1: String(Math.floor(tst / 60)),
        p2_q4_2: String(tst - Math.floor(tst / 60) * 60),
      });
    }
  }, [
    values["p2_q3_1"],
    values["p2_q3_2"],
    values["p2_q3_3"],
    values["p2_q3_4"],
    values["p2_q3_5"],
  ]);

  const changeValue = (name: string, newValue: string) => {
    setValues({
      ...values,
      [name]: newValue,
    });
  };

  const onChange_changeValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = e.target.name;
    const newValue = e.target.value;
    changeValue(name, newValue);
  };

  const onChange_changeValue_checked = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = e.target.name;
    const newValue = String(Number(e.target.checked));
    changeValue(name, newValue);
  };

  const handleChange = (event: any, nextName?: string) => {
    // console.log(event.target.name);
    if (nextName !== undefined) scrollTo(nextName);
  };

  const RadioButtonInput = (p: {
    name: string;
    question: string;
    choises: string[];
    description?: string;
    next?: string;
    startValue?: number;
  }) => (
    <Grid item xs={12}>
      <FormControl component="fieldset" required style={{ width: "100%" }}>
        <FormLabel component="legend">
          <span
            dangerouslySetInnerHTML={{
              __html: p.question,
            }}
          />
        </FormLabel>
        {p.description && (
          <Typography
            variant="caption"
            component="div"
            style={{ marginTop: 10 }}
          >
            {p.description}
          </Typography>
        )}
        <RadioGroup
          row={!isMobile}
          id={p.name}
          name={p.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(e, p.next);
          }}
          style={{
            width: "100%",
            ...(isMobile
              ? { marginTop: 5 }
              : { margin: "5px auto 0px", padding: "0px -1px" }),
          }}
        >
          {p.choises.map((choise, choise_i) => (
            <Radio
              key={choise_i}
              value={choise_i + (p.startValue === undefined ? 1 : p.startValue)}
              required={true}
              icon={
                <span
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    padding: theme.spacing(1),
                  }}
                >
                  {choise}
                </span>
              }
              checkedIcon={
                <span
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    padding: theme.spacing(1),
                    backgroundColor: lighten(theme.palette.primary.main, 0.7),
                    fontWeight: 600,
                  }}
                >
                  {choise}
                </span>
              }
              style={{
                borderRadius: 0,
                backgroundColor: "#f5f5f5",
                padding: 0,
                whiteSpace: "nowrap",
                ...(isMobile
                  ? { width: "100%", margin: "1px 0" }
                  : p.choises.length == 3
                  ? {
                      width: "calc(33% - 2px)",
                      margin: "1px 1px",
                    }
                  : {
                      width: "50%",
                      margin: "1px 0",
                      marginLeft: choise_i % 2 === 0 ? -1 : 1,
                      marginRight: choise_i % 2 === 0 ? 1 : -1,
                    }),
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );

  const RadioButtonInputControlled = (p: {
    name: string;
    question: string;
    choises: string[];
    description?: string;
    next?: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }) => (
    <Grid item xs={12}>
      <FormControl component="fieldset" required style={{ width: "100%" }}>
        <FormLabel component="legend">
          <span
            dangerouslySetInnerHTML={{
              __html: p.question,
            }}
          />
        </FormLabel>
        {p.description && (
          <Typography
            variant="caption"
            component="div"
            style={{ marginTop: 10 }}
          >
            {p.description}
          </Typography>
        )}
        <RadioGroup
          row={!isMobile}
          id={p.name}
          name={p.name}
          value={p.value !== "" ? p.value : null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            p.onChange(e);
            handleChange(e, p.next);
          }}
          style={{
            width: "100%",
            ...(isMobile
              ? { marginTop: 5 }
              : { margin: "5px auto 0px", padding: "0px -1px" }),
          }}
        >
          {p.choises.map((choise, choise_i) => (
            <Radio
              key={choise_i}
              value={choise_i + 1}
              required={true}
              icon={
                <span
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    padding: theme.spacing(1),
                  }}
                >
                  {choise}
                </span>
              }
              checkedIcon={
                <span
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    padding: theme.spacing(1),
                    backgroundColor: lighten(theme.palette.primary.main, 0.7),
                    fontWeight: 600,
                  }}
                >
                  {choise}
                </span>
              }
              style={{
                borderRadius: 0,
                backgroundColor: "#f5f5f5",
                padding: 0,
                whiteSpace: "nowrap",
                ...(isMobile
                  ? { width: "100%", margin: "1px 0" }
                  : p.choises.length == 3
                  ? {
                      width: "calc(33% - 2px)",
                      margin: "1px 1px",
                    }
                  : {
                      width: "50%",
                      margin: "1px 0",
                      marginLeft: choise_i % 2 === 0 ? -1 : 1,
                      marginRight: choise_i % 2 === 0 ? 1 : -1,
                    }),
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );

  const ButtonInputWithCheckBoxShowing = (p: {
    name: string;
    question: string;
    btn_question: string;
    btn_choises: string[];
    description?: string;
    next?: string;
    startValue?: number;
  }) => {
    const [checked, setChecked] = React.useState<boolean>(false);

    return (
      <React.Fragment>
        <input type="hidden" name={p.name} value="0" />
        <div
          style={{
            marginTop: "1rem",
            border: "2px dashed #ccc",
            padding: "7.5px 5px",
          }}
        >
          <FormControlLabel
            value="1"
            control={
              <Checkbox
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
              />
            }
            labelPlacement="end"
            label={p.question}
            style={{ width: "100%" }}
          />
          {checked && (
            <div style={{ margin: "5px auto" }}>
              {RadioButtonInput({
                name: p.name,
                next: p.next,
                question: p.btn_question,
                choises: p.btn_choises,
                description: p.description,
                startValue: p.startValue,
              })}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  const ButtonInputWithCheckBoxShowingControlled = (p: {
    name: string;
    question: string;
    btn_question: string;
    btn_choises: string[];
    description?: string;
    next?: string;
    value: string;
    changeValue: (v: string) => void;
  }) => {
    const [checked, setChecked] = React.useState<boolean>(false);

    const name = p.name as keyof VALUES;
    const value = values[name] as string;

    React.useEffect(() => {
      if (!checked) {
        const newValue = String(Number(checked));
        p.changeValue(newValue);
      }
    }, [checked]);

    return (
      <React.Fragment>
        <div
          style={{
            marginTop: "1rem",
            border: "2px dashed #ccc",
            padding: "7.5px 5px",
          }}
        >
          <FormControlLabel
            value="1"
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
              />
            }
            labelPlacement="end"
            label={p.question}
            style={{ width: "100%" }}
          />
          {checked && (
            <div style={{ margin: "5px auto" }}>
              {RadioButtonInputControlled({
                name: p.name,
                next: p.next,
                question: p.btn_question,
                choises: p.btn_choises,
                description: p.description,
                value: values[name],
                onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
                  const newValue = e.target.value;
                  p.changeValue(newValue);
                },
              })}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div style={{ maxWidth: 900, margin: "auto" }}>
        <Grid container spacing={Spacing}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h5" align="center">
              第２期和光市自殺対策計画における市民調査
            </Typography>
            <Typography component="h2" variant="h6" align="center">
              生活習慣やこころの状態についてのアンケート
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <div style={{ backgroundColor: "#f1f1f1", padding: 20 }}>
              {/* <Typography
              variant="subtitle1"
              style={{ fontWeight: 800 }}
              gutterBottom
            >
              概要
            </Typography> */}
              <Typography variant="body2">
                質問は大きく3つあります。回答に5-10分ほどを要します。お手数おかけしますがどうぞよろしくお願いいたします。
                <br />
                <br />
                なお、本アンケートの結果は市民の健康づくりのための大切な基礎資料として役立てるほか、大学との協働により公衆衛生(多くの人の健康づくり)の研究のために利用させていただく予定です。
                <br />
                研究目的で利用する際は、どれがどなたの回答か完全にわからない状態での活用とさせていただきますが、同意されない場合は、こちらにチェックをしてください。
              </Typography>

              <input type="hidden" name={`p0_q1`} value="0" />
              <FormControlLabel
                label="同意しない"
                value="1"
                control={
                  <Checkbox
                    name="p0_q1"
                    checked={Boolean(parseInt(values["p0_q1"]))}
                    onChange={onChange_changeValue_checked}
                  />
                }
              />
            </div>
          </Grid>

          <React.Fragment>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: theme.spacing(2 - Spacing),
              }}
            >
              <ScrollElement name="p1" />
              <Typography
                variant="h5"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                (1) 食生活について
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginBottom: theme.spacing(2 - Spacing),
              }}
            >
              <ScrollElement name="p1-1" />
              <Typography variant="h6" gutterBottom>
                食事の頻度と時間について教えてください
              </Typography>
            </Grid>

            {RadioButtonInputControlled({
              name: "p1_q1",
              next: "p1_q2",
              question: "朝食はだいたい同じ時間に毎日食べている",
              choises: ["はい", "いいえ"],
              value: values["p1_q1"],
              onChange: onChange_changeValue,
            })}

            {RadioButtonInputControlled({
              name: "p1_q2",
              next: "p1_q3",
              question: "昼食はだいたい同じ時間に毎日食べている",
              choises: ["はい", "いいえ"],
              value: values["p1_q2"],
              onChange: onChange_changeValue,
            })}

            {RadioButtonInputControlled({
              name: "p1_q3",
              next: "p1_q4",
              question: "夕食はだいたい同じ時間に毎日食べている",
              choises: ["はい", "いいえ"],
              value: values["p1_q3"],
              onChange: onChange_changeValue,
            })}

            {RadioButtonInputControlled({
              name: "p1_q4",
              next: "p1-2",
              question: "一週間の中で、食事の時間は全体的に",
              choises: ["規則正しい", "不規則"],
              value: values["p1_q4"],
              onChange: onChange_changeValue,
            })}

            <Grid
              item
              xs={12}
              style={{
                marginBottom: theme.spacing(2 - Spacing),
              }}
            >
              <ScrollElement name="p1-2" />
              <Typography variant="h6" gutterBottom>
                食事の中身や嗜好品について教えてください
              </Typography>
            </Grid>

            {RadioButtonInputControlled({
              name: "p1_q5",
              next: "p1_q6",
              question:
                "野菜・きのこ・海藻・豆・果物など、食物繊維を含むものを食べる頻度は",
              choises: ["毎日食べる", "毎食食べる", "食べない日がある"],
              value: values["p1_q5"],
              onChange: onChange_changeValue,
            })}

            {RadioButtonInputControlled({
              name: "p1_q6",
              next: "p1_q7",
              question:
                "1日の野菜類の合計摂取量は推奨量(350g、食物繊維として20g)※に対してどのくらいですか？",
              choises: [
                "推奨量（350g）くらい",
                "推奨量の半分はとれている",
                "推奨量の半分未満しかとれていない",
                "分からない",
              ],
              value: values["p1_q6"],
              onChange: onChange_changeValue,
            })}

            <Grid
              item
              xs={12}
              style={{
                marginTop: theme.spacing(2 - Spacing),
              }}
            >
              <Typography variant="body2">
                ※目安は以下の通り。
                <br />
                食材にするとレタス6玉、トマト10個、切り干し大根(乾燥)100g、生おから200g、納豆6パック、りんご10個、市販の野菜ジュース10本分等に相当します。
                <span
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <img
                    src="image1.png"
                    alt=""
                    width="100%"
                    style={{ maxWidth: 600 }}
                  />
                  <img
                    src="image2.png"
                    alt=""
                    width="100%"
                    style={{ maxWidth: 600 }}
                  />
                </span>
              </Typography>
            </Grid>

            {RadioButtonInputControlled({
              name: "p1_q7",
              next: "p1_q8",
              question: "タンパク質(魚・肉・卵・豆・乳製品等)を食べる頻度は",
              choises: ["毎日食べる", "毎食食べる", "食べない日がある"],
              value: values["p1_q7"],
              onChange: onChange_changeValue,
            })}

            {RadioButtonInputControlled({
              name: "p1_q8",
              next: "p1_q9",
              question:
                "1日のタンパク質の合計摂取量は推奨量(50-65g)に対してどのくらいですか？",
              choises: [
                "推奨量（50-65g）くらいとっている",
                "推奨量の半分くらいとっている",
                "推奨量の半分未満しかとれていない",
                "分からない",
              ],
              value: values["p1_q8"],
              onChange: onChange_changeValue,
            })}
            <Grid
              item
              xs={12}
              style={{
                marginTop: theme.spacing(2 - Spacing),
              }}
            >
              <Typography variant="body2">
                ※目安は以下の通り。
                <br />
                食材にすると魚の干物で3匹、ハンバーグやステーキで300g、卵8個、豆腐3丁、納豆8パック等に相当します。
                <span
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <img
                    src="image3.png"
                    alt=""
                    width="100%"
                    style={{ maxWidth: 200 }}
                  />
                </span>
              </Typography>
            </Grid>

            {RadioButtonInputControlled({
              name: "p1_q9",
              next: "p1_q10",
              question: "1日あたりどの程度水分(飲料)を摂取してますか？",
              choises: [
                "2000mL以上飲む",
                "1000-1999mL程度飲む",
                "500mL-999ｍL程度飲む",
                "500mL未満",
              ],
              description:
                "※自販機のペットボトルは約500mL、マグカップは約250mL、紙コップは約150mLです。",
              value: values["p1_q9"],
              onChange: onChange_changeValue,
            })}

            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                name={`p1_q10`}
                style={{ width: "100%" }}
              >
                <FormLabel component="legend">
                  以下にあてはまるものはありますか？
                </FormLabel>
                <FormGroup
                  style={
                    isMobile ? { paddingLeft: 40 } : { margin: "0px auto 0px" }
                  }
                >
                  {[
                    "砂糖類の入った清涼飲料水をよく飲む",
                    "鉄分を含む飲食料品を積極的に摂取するようにしている",
                    "眠る直前に何かを食べることが多い",
                    "タバコを吸う(加熱式・電子タバコ等を含みます)",
                    <>
                      夕方以降にカフェインを含む飲み物*を飲むことが多い
                      <span style={{ fontSize: "0.85rem", color: "#333" }}>
                        <br />
                        ※カフェインは、コーヒー、茶葉(緑茶・紅茶・ウーロン茶)、エナジードリンク、栄養ドリンク、コーラ、ココアなどに含まれます。麦茶やハーブティーには含まれません。
                      </span>
                    </>,
                    <>
                      カフェインを含む飲み物をたくさん(1日400mg分以上)飲む
                      <span style={{ fontSize: "0.85rem", color: "#333" }}>
                        <br />
                        ※1日合計、コーヒーマグカップ2杯(ペットボトル1.5本)、お茶で1.5L程度以上
                      </span>
                    </>,
                    <>
                      アルコールを飲む場合、日本酒換算で4合以上飲む
                      <span style={{ fontSize: "0.85rem", color: "#333" }}>
                        <br />
                        ※日本酒１合のアルコール量はビール中ジョッキ１杯分と同等です。
                      </span>
                    </>,
                    "就寝前の2時間以内にお酒を飲むことが多い",
                  ].map((choise, choise_i) => {
                    const name = `p1_q10_${choise_i + 1}` as keyof VALUES;
                    const value = values[name] as string;
                    return (
                      <React.Fragment key={choise_i}>
                        <FormControlLabel
                          value="1"
                          control={
                            <Checkbox
                              name={name}
                              checked={Boolean(parseInt(value))}
                              onChange={onChange_changeValue_checked}
                            />
                          }
                          labelPlacement="end"
                          label={choise}
                          style={{
                            marginTop: "1rem",
                            border: "2px dashed #ccc",
                            padding: "7.5px 5px",
                            width: "100%",
                          }}
                        />
                      </React.Fragment>
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </React.Fragment>

          <React.Fragment>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: theme.spacing(2 - Spacing),
              }}
            >
              <ScrollElement name="p2" />
              <Typography
                variant="h5"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                (2) 睡眠について
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginBottom: theme.spacing(2 - Spacing),
              }}
            >
              <ScrollElement name="p2-1" />
              <Typography variant="h6" gutterBottom>
                過去１カ月間の、あなたの通常の睡眠の習慣について、答えてください。
              </Typography>
            </Grid>

            {RadioButtonInputControlled({
              name: "p2_q1",
              next: "p2_q2",
              question: "ここ1ヶ月間、あなたは睡眠で休養が充分とれていますか？",
              choises: ["はい", "いいえ"],
              value: values["p2_q1"],
              onChange: onChange_changeValue,
            })}

            {RadioButtonInputControlled({
              name: "p2_q2",
              next: "p2_q3",
              question:
                "自分の睡眠の質を、全体的にみてどのように評価しますか？",
              choises: ["非常に良い", "かなり良い", "かなり悪い", "非常に悪い"],
              value: values["p2_q2"],
              onChange: onChange_changeValue,
            })}

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                通常、何時頃寝床について、眠るまでに何分かかり、何時頃起床しますか？
                <span>*</span>
              </Typography>
              <Typography
                variant="caption"
                component="div"
                style={{ marginTop: 10 }}
              >
                ※ばらつく場合や交代勤務がある場合等は、典型的な日のことを教えてください
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <TextField
                  required
                  name="p2_q3_1"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q3_1"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from(
                    { length: 24 },
                    (_, index) => ((16 + index) % 24) + 1
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時</span>
                <TextField
                  required
                  name="p2_q3_2"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q3_2"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from(
                    { length: (55 - 0) / 5 + 1 },
                    (_, index) => 0 + index * 5
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>分頃寝床に入り、</span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <TextField
                  required
                  name="p2_q3_3"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q3_3"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {[
                    0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 80, 100, 120, 150,
                    180, 210, 240,
                  ].map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>分くらいで寝付く。</span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <TextField
                  required
                  name="p2_q3_4"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q3_4"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from(
                    { length: 24 },
                    (_, index) => ((3 + index) % 24) + 1
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時</span>
                <TextField
                  required
                  name="p2_q3_5"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q3_5"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from(
                    { length: (55 - 0) / 5 + 1 },
                    (_, index) => 0 + index * 5
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>分頃起床する。</span>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                実際の睡眠時間は何時間くらいですか？
                <span>*</span>
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <TextField
                  required
                  name="p2_q4_1"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q4_1"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from({ length: 24 }, (_, index) => index % 24).map(
                    (v, index) => (
                      <option key={index} value={v}>
                        {v}
                      </option>
                    )
                  )}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時間</span>
                <TextField
                  required
                  name="p2_q4_2"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q4_2"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from(
                    { length: (55 - 0) / 5 + 1 },
                    (_, index) => 0 + index * 5
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>分くらい</span>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                休日(特に夜更かししたり、早起きしたりする予定もない日)はどうですか？
                <span>*</span>
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <TextField
                  required
                  name="p2_q5_1"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q5_1"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from(
                    { length: 24 },
                    (_, index) => ((16 + index) % 24) + 1
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時</span>
                <TextField
                  required
                  name="p2_q5_2"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q5_2"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from(
                    { length: (55 - 0) / 5 + 1 },
                    (_, index) => 0 + index * 5
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>分頃寝床に入り、</span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <TextField
                  required
                  name="p2_q5_3"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q5_3"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {[
                    0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 80, 100, 120, 150,
                    180, 210, 240,
                  ].map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>分くらいで寝付く。</span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <TextField
                  required
                  name="p2_q5_4"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q5_4"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from(
                    { length: 24 },
                    (_, index) => ((3 + index) % 24) + 1
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時</span>
                <TextField
                  required
                  name="p2_q5_5"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p2_q5_5"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from(
                    { length: (55 - 0) / 5 + 1 },
                    (_, index) => 0 + index * 5
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}
                    </option>
                  ))}
                </TextField>
                <span style={{ padding: "0px 5px" }}>分頃起床する。</span>
              </div>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                name={`p2_q6`}
                style={{ width: "100%" }}
              >
                <FormLabel component="legend">
                  以下の理由のために睡眠が困難なことがありましたか？
                </FormLabel>
                <FormGroup>
                  {[
                    "寝床についてから30分以内に眠れなかった",
                    "夜中、または早朝に目が覚めた",
                    "トイレに起きた",
                    "息苦しかった",
                    "せきが出たり、大きないびきをかいたりした",
                    "ひどく寒く感じた",
                    "ひどく暑く感じた",
                    "悪い夢を見た",
                    "痛みがあった",
                    "肩・腰のこりがつらかった",
                    "痒み、鼻詰まりなどがつらかった",
                    "寝ている最中に息が止まった",
                    "睡眠中に寝言を言ったり体が動いたりした",
                    "夜に脚がムズムズ・そわそわしたり、ぴくついたりした",
                    "上記以外の理由で睡眠が困難なことがあった",
                  ].map((choise, choise_i) => {
                    const name = `p2_q6_${choise_i + 1}` as keyof VALUES;
                    const value = values[name] as string;

                    return (
                      <React.Fragment key={choise_i}>
                        {ButtonInputWithCheckBoxShowingControlled({
                          name: name,
                          question: choise,
                          btn_question: "その頻度は？",
                          btn_choises: [
                            "1週間に1回未満",
                            "1週間に1-2回",
                            "1週間に3回以上",
                          ],
                          value: value,
                          changeValue: (v) => {
                            changeValue(name, v);
                          },
                        })}
                      </React.Fragment>
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                name={`p2_q7`}
                style={{ width: "100%" }}
              >
                <FormLabel component="legend">
                  以下にあてはまるものがあればチェックしてください
                </FormLabel>
                <FormGroup>
                  {[
                    "望ましい時間に寝起きすることができない",
                    "眠るために薬を服用した",
                    "眠るためにお酒を飲んだ",
                    "車の運転や食事中、その他の活動中(仕事・学校等)に、眠くて起きていられなかった",
                    "物事をやり遂げるために必要な意欲を持続するのに、問題があった",
                    "スマートフォンなどの光の出る電子機器を寝床で使う",
                    "寝室があまり静かではない",
                    "夜にお風呂に入らない、もしくは眠る直前に入る",
                    "寝ている間に寝室が真っ暗ではない",
                    "寝室が暑い、もしくは寒いが、空調は使わない",
                    "朝や日中に、屋外の明るい光をあまり浴びない",
                    "眠る直前に家事や仕事、勉強などをすることが多い",
                  ].map((choise, choise_i) => {
                    const name = `p2_q7_${choise_i + 1}` as keyof VALUES;
                    const value = values[name] as string;

                    return (
                      <React.Fragment key={choise_i}>
                        {ButtonInputWithCheckBoxShowingControlled({
                          name: name,
                          question: choise,
                          btn_question: "その頻度は？",
                          btn_choises: [
                            "1週間に1回未満",
                            "1週間に1-2回",
                            "1週間に3回以上",
                          ],
                          value: value,
                          changeValue: (v) => {
                            changeValue(name, v);
                          },
                        })}
                      </React.Fragment>
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                name={`p2_q8`}
                style={{ width: "100%" }}
              >
                <FormLabel component="legend">
                  以下はメンタルヘルスに関する質問です。答えなくても構いません。あてはまるものがあればチェックしてください
                </FormLabel>
                <FormGroup>
                  {[
                    "物事に対してほとんど興味がない、または楽しめない",
                    "気分が落ち込む、憂うつになる、または絶望的な気持ちになる",
                    "寝付きが悪い、途中で目がさめる、または逆に眠り過ぎる",
                    "疲れた感じがする、または気力がない",
                    "あまり食欲がない、または食べ過ぎる",
                    "自分はダメな人間だ、人生の敗北者だと気に病む、または自分自身あるいは家族に申し訳がないと感じる",
                    "新聞を読む、またはテレビを見ることなどに集中することが難しい",
                    "他人が気づくぐらいに動きや話し方が遅くなる、あるいはこれと反対に、そわそわしたり、落ち着かず、ふだんよりも動き回ることがある",
                    "死んだ方がましだと、あるいは自分を何らかの方法で傷つけようと思ったことがある",
                  ].map((choise, choise_i) => {
                    const name = `p2_q8_${choise_i + 1}` as keyof VALUES;
                    const value = values[name] as string;

                    return (
                      <React.Fragment key={choise_i}>
                        {ButtonInputWithCheckBoxShowingControlled({
                          name: name,
                          question: choise,
                          btn_question: "その頻度は？",
                          btn_choises: ["数日", "週の半分以上", "ほとんど毎日"],
                          value: value,
                          changeValue: (v) => {
                            changeValue(name, v);
                          },
                        })}
                      </React.Fragment>
                    );
                  })}
                </FormGroup>
              </FormControl>
            </Grid>

            {is_show_p2_q8_10 &&
              RadioButtonInputControlled({
                name: "p2_q8_10",
                next: "p3",
                question:
                  "上記のメンタルヘルスに関する質問で、一つもチェックをしなかった理由を教えてください",
                choises: ["上記にあてはまるものはなかった", "答えたくない"],
                value: values["p2_q8_10"],
                onChange: onChange_changeValue,
              })}
          </React.Fragment>

          <React.Fragment>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: theme.spacing(2 - Spacing),
              }}
            >
              <ScrollElement name="p3" />
              <Typography
                variant="h5"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                (3) ふだんの過ごし方について
              </Typography>
            </Grid>

            {RadioButtonInputControlled({
              name: "p3_q1",
              next: "p3_q2",
              question: "継続的な運動習慣(週に2日、1回30分以上)はありますか？",
              choises: ["ある", "ない"],
              description: "※通勤通学時の歩行や筋力トレーニングなどを含みます",
              value: values["p3_q1"],
              onChange: onChange_changeValue,
            })}

            {RadioButtonInputControlled({
              name: "p3_q2",
              // next: "p3_q3",
              question: "歩く早さは人と比べてどうですか？",
              choises: ["早い", "ふつう", "遅い"],
              value: values["p3_q2"],
              onChange: onChange_changeValue,
            })}

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                歩行や家事、仕事中の移動等を含めて、なにかしら体を動かしている時間(座ったり寝たりしていない時間)は、1日のうち合計何時間くらいですか？
                <span>*</span>
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <span style={{ padding: "0px 5px" }}>平均</span>
                <TextField
                  required
                  name="p3_q3"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p3_q3"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from({ length: 21 }, (_, index) => index).map(
                    (v, index) => (
                      <option key={index} value={v}>
                        {v}
                      </option>
                    )
                  )}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時間</span>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                通勤・通学や屋外作業を含めて、日中に屋外あるいは屋外に近い明るさの場所(自動車の運転席・助手席、大きな窓のそば、透明なビニールハウスの中など)で過ごす時間は、1日のうち合計何時間くらいですか？
                <span>*</span>
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <span style={{ padding: "0px 5px" }}>平均</span>
                <TextField
                  required
                  name="p3_q4"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p3_q4"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from({ length: 21 }, (_, index) => index).map(
                    (v, index) => (
                      <option key={index} value={v}>
                        {v}
                      </option>
                    )
                  )}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時間</span>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                以下の行動にそれぞれ費やす、1日の中での平均的な合計時間を教えてください。
                <span>*</span>
              </Typography>

              <div style={{ marginTop: "1rem" }}>・家事・育児:</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: "3rem",
                }}
              >
                <span style={{ padding: "0px 5px" }}>平均</span>
                <TextField
                  required
                  name="p3_q5_1"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p3_q5_1"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from({ length: 21 }, (_, index) => index).map(
                    (v, index) => (
                      <option key={index} value={v}>
                        {v}
                      </option>
                    )
                  )}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時間</span>
              </div>

              <div style={{ marginTop: "1rem" }}>・介護:</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: "3rem",
                }}
              >
                <span style={{ padding: "0px 5px" }}>平均</span>
                <TextField
                  required
                  name="p3_q5_2"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p3_q5_2"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from({ length: 21 }, (_, index) => index).map(
                    (v, index) => (
                      <option key={index} value={v}>
                        {v}
                      </option>
                    )
                  )}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時間</span>
              </div>

              <div style={{ marginTop: "1rem" }}>・通勤・通学:</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: "3rem",
                }}
              >
                <span style={{ padding: "0px 5px" }}>平均</span>
                <TextField
                  required
                  name="p3_q5_3"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p3_q5_3"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from({ length: 21 }, (_, index) => index).map(
                    (v, index) => (
                      <option key={index} value={v}>
                        {v}
                      </option>
                    )
                  )}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時間</span>
              </div>

              <div style={{ marginTop: "1rem" }}>・ネット動画の視聴:</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: "3rem",
                }}
              >
                <span style={{ padding: "0px 5px" }}>平均</span>
                <TextField
                  required
                  name="p3_q5_4"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p3_q5_4"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from({ length: 21 }, (_, index) => index).map(
                    (v, index) => (
                      <option key={index} value={v}>
                        {v}
                      </option>
                    )
                  )}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時間</span>
              </div>

              <div style={{ marginTop: "1rem" }}>
                ・SNS(X、Facebook、Instagram等)の利用・閲覧:
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginLeft: "3rem",
                }}
              >
                <span style={{ padding: "0px 5px" }}>平均</span>
                <TextField
                  required
                  name="p3_q5_5"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 80 }}
                  value={values["p3_q5_5"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  {Array.from({ length: 21 }, (_, index) => index).map(
                    (v, index) => (
                      <option key={index} value={v}>
                        {v}
                      </option>
                    )
                  )}
                </TextField>
                <span style={{ padding: "0px 5px" }}>時間</span>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                現在の身長と体重を教えてください
                <span>*</span>
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <span style={{ padding: "0px 5px" }}>身長:</span>
                <TextField
                  required
                  name="p3_q6_1"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 120 }}
                  value={values["p3_q6_1"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  <option value="129">130cm未満</option>
                  {Array.from(
                    { length: 199 - 130 + 1 },
                    (_, index) => 130 + index
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}cm
                    </option>
                  ))}
                  <option value="200">200cm以上</option>
                  <option value="-1">答えたくない</option>
                </TextField>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <span style={{ padding: "0px 5px" }}>体重:</span>
                <TextField
                  required
                  name="p3_q6_2"
                  variant="filled"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  hiddenLabel
                  size="small"
                  style={{ width: 120 }}
                  value={values["p3_q6_2"]}
                  onChange={onChange_changeValue}
                >
                  <option value=""></option>
                  <option value="29">30kg未満</option>
                  {Array.from(
                    { length: 199 - 30 + 1 },
                    (_, index) => 30 + index
                  ).map((v, index) => (
                    <option key={index} value={v}>
                      {v}kg
                    </option>
                  ))}
                  <option value="200">200kg以上</option>
                  <option value="-1">答えたくない</option>
                </TextField>
              </div>
            </Grid>

            {RadioButtonInputControlled({
              name: "p3_q7",
              next: "p3_q8",
              question: "健康診断で再検査や要治療などの異常はありましたか？",
              choises: [
                "なし",
                "異常あり/検査・治療済",
                "異常あり/放置",
                "健診を受けていない",
              ],
              value: values["p3_q7"],
              onChange: onChange_changeValue,
            })}

            {RadioButtonInputControlled({
              name: "p3_q8",
              next: undefined,
              question:
                "高血圧、高血糖、脂質異常症等の生活習慣病はありますか？",
              choises: [
                "なし",
                "あり/治療中",
                "あり/未治療",
                "わからない/未検査",
              ],
              value: values["p3_q8"],
              onChange: onChange_changeValue,
            })}
          </React.Fragment>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Component;
