import { scroller } from "react-scroll";

export const scrollTo = (name: string, offset?: number) => {
  scroller.scrollTo(name, {
    duration: 200,
    delay: 0,
    smooth: true,
    offset: offset === undefined ? -window.innerHeight / 3 : offset,
  });
};
